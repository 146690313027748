import { v4 as uuidv4 } from 'uuid';

const isEmpty = (obj: any) => !Object.values(obj).filter(e => typeof e !== 'undefined').length;

const parseResponse = (text: any) => {
    try {
        return { data: JSON.parse(text) };
    } catch (e: any) {
        return { data: text };
    }
}

const xhr = async (accessToken: string, url: string, method: string = "GET", params: any = {}, headers: any = {}, successHandler: Function, errorHandler: Function, authHandler: Function) => {
    headers["Authorization"] = "Bearer " + accessToken;
    headers["Scs-Application-Id"] = "8c3fe2e8-2ad2-407a-9187-5559929267e2_web-ui";
    headers["Scs-Correlation-Id"] = uuidv4();

    const urlWithParams = isEmpty(params) ? url : url + "?" + new URLSearchParams(params);
    const xhrFetch = (retry: boolean) => fetch(urlWithParams, { method: method, headers: headers }).then(
        (response: Response) => {
            if (response.status === 401 && retry) {
                authHandler();
                xhrFetch(false);
            } else {
                (response.status >= 400 ? errorHandler : successHandler)(response);
            }
        }
    );
    await xhrFetch(true);
}

export const AjaxCall = (props: any) => {
    const accessToken = props.appConfig.authContext?.userData?.access_token;
    const authHandler = () => {
        props.appConfig.authContext?.signOut();
        props.appConfig.authContext?.signIn();
    }
    xhr(accessToken, props.url, props.method, props.params, props.headers, props.successHandler, props.errorHandler, authHandler);
}

export default AjaxCall;