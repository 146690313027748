import { AuthContextProps, AuthProviderProps, User, UserManager} from 'oidc-react';
import { UserManagerSettings } from 'oidc-client-ts';

const getBaseUrl = (urlSource: any) => new URL(urlSource.toString()).origin;

const getAuthUserAttributes = (authContext: AuthContextProps) => {
    return authContext?.userData ? {
        employeeId: ((authContext?.userData?.profile?.user_attributes as any[])?.[0]?.employee_id as string[])?.[0] as string,
        employeeEmail: authContext?.userData?.profile?.email as string
    } : undefined;
}

const userManagerSettings = (umSettings: any): UserManagerSettings => {
    const urlParams = new URL(window.location.href).searchParams;
    if (urlParams.has("code")) {
        const umSettingsJson = window.localStorage.getItem("UserManagerSettings");
        if (umSettingsJson) {
            return JSON.parse(umSettingsJson);
        }
    } else {
        window.localStorage.setItem("UserManagerSettings", JSON.stringify(umSettings));
    }
    return umSettings;
}

export const envConfig = async (): Promise<EnvConfig> => await fetch("/env-config").then(res => res.json());

export const oidcConfig = (envConfig: EnvConfig): AuthProviderProps => ({
    userManager: new UserManager(userManagerSettings(Object.assign(envConfig.ssoCredentials, {
        response_type: "code",
        scope: "openid,profile,user_attributes",
        redirect_uri: getBaseUrl(window.location),
        automaticSilentRenew: false
    }))),
    onSignIn: (user: User | null) => {
        window.location.replace(window.location.href.split("?")[0].split("#")[0]);
    }
})

export const appConfig = (envConfig: EnvConfig, authContext: AuthContextProps): AppConfig => {
    const authUserAttributes = getAuthUserAttributes(authContext);
    if (authUserAttributes) {
        const userAttributes = envConfig.userAttributes ? envConfig.userAttributes : authUserAttributes;
        return {
            balanceUrl: "/api/eai/v1/balance",
            transactionsUrl: "/api/eai/v1/transactions",
            transactionsXlsExportUrl: "/api/eai/v1/transactions/xls-export",
            authContext: authContext,
            userAttributes: userAttributes
        };
    }
    return null;
}