import { useState, useEffect } from 'react';
import { renderToString } from 'react-dom/server'
import dayjs from 'dayjs';
import AjaxCall from '../components/AjaxCall';
import Format from '../components/Format';
import Message from '../components/Message';
import TransactionDetails from '../components/TransactionDetails';
import DateFilter from '../components/DateFilter';
import fileDownload from 'js-file-download';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import xlsIcon from '../assets/xls-icon.svg';
import { useTranslation } from 'react-i18next';

const TransactionsTable = (appConfig: AppConfig) => {
  dayjs.extend(require('dayjs/plugin/utc'));
  dayjs.extend(require('dayjs/plugin/customParseFormat'));

  const [dateRange, setDateRange] = useState<Date[]>([dayjs().startOf("month").toDate(), dayjs().endOf("month").toDate()]);
  const [startDate, endDate] = dateRange;
  const { t } = useTranslation();

  let credit = 0.0;
  let debit = 0.0;

  const renderTransactionsTable = () => {
    return !transactionResponse?.transactions || transactionResponse?.transactions?.length === 0 ?
      transactionResponse && <Message value={t('transactions.NO_TRANSACTIONS')} /> :
      (
        <div>
          <table className="table" id="transactionsTable">
            <thead>
              <tr>
                <th>{t('transactions.DATE_TIME')}</th>
                <th>{t('transactions.TILL')}</th>
                <th>{t('transactions.CREDIT')}</th>
                <th>{t("transactions.DEBIT")}</th>
                <th>{t('transactions.BALANCE')}</th>
                <th><span className="linkimg" style={{ textDecoration: "none" }} onClick={e => ajaxCall(true)}><img src={xlsIcon} alt="Download Excel" title="Download Excel" /></span></th>
              </tr>
            </thead>
            <tbody>
              {
                transactionResponse?.transactions?.map((trx: Transaction, i: number) => {
                  credit += parseFloat(trx?.credit);
                  debit += parseFloat(trx?.debit);
                  const trxRows = [
                    <tr key={i}>
                      <td><Format value={trx?.date} format={"DD MMMM YYYY HH:mm:ss"} /></td>
                      <td>{trx?.till}</td>
                      <td>{<Format value={trx?.credit} currency="CHF" />}</td>
                      <td>{<Format value={trx?.debit} currency="CHF" showzero={!trx?.credit} />}</td>
                      <td>{<Format value={trx?.balance} currency="CHF" />}</td>
                      {trx?.products?.length !== 0 && (<td><span className="link" onClick={e => { e.preventDefault(); setDetailTransaction(trx); }} title="Show the transaction details">{t('transactions.DETAILS')}</span></td>)}
                    </tr>,
                  ]
                  return trxRows;
                })
              }
              <tr>
                <td><b>{t('transactions.TOTAL')}</b></td>
                <td></td>
                <td><b><Format value={credit} currency="CHF" showzero={true} /></b></td>
                <td><b><Format value={debit} currency="CHF" showzero={true} /></b></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      )
  };

  const [transactionResponse, setTransactionResponse] = useState<TransactionResponse>();
  const [detailTransaction, setDetailTransaction] = useState<Transaction>();
  const [errorResponse, setErrorResponse] = useState<any>();

  const ajaxCall = (xlsDownload: boolean) => AjaxCall({
    url: xlsDownload ? appConfig.transactionsXlsExportUrl : appConfig.transactionsUrl,
    params: appConfig.userAttributes?.employeeId ? {
      employeeId: appConfig.userAttributes.employeeId,
      dateFrom: dayjs(startDate).utc().format(),
      dateTo: dayjs(endDate).utc().format()
    } : {
      dateFrom: dayjs(startDate).utc().format(),
      dateTo: dayjs(endDate).utc().format()
    },
    appConfig: appConfig,
    initHandler: () => {
      if (startDate && endDate) {
        setTransactionResponse(null);
        setDetailTransaction(null);
        setErrorResponse(null);
        return true;
      }
      return false;
    },
    successHandler: (response: Response) => xlsDownload ? xlsExportDownload(response) : response.json().then(data => setTransactionResponse(data)),
    errorHandler: (response: Response) => response.json().then(data => setErrorResponse(data)),
    dependencies: [dateRange]
  });

  const xlsExportDownload = (response: Response) => {
    const defaultFileName = "Transactions_" + renderToString(<Format value={startDate} format={"DD-MM-YYYY"} />) + "_" + renderToString(<Format value={endDate} format={"DD-MM-YYYY"} />) + ".xlsx";
    var fileName = response.headers.get('Content-Disposition')?.split('filename=')[1].split(';')[0] || defaultFileName;
    response.blob().then(data => fileDownload(data, fileName));
  }

  useEffect(() => {
    if (startDate && endDate) {
      setTransactionResponse(null);
      setDetailTransaction(null);
      setErrorResponse(null);
      ajaxCall(false);
    }
  }, [dateRange]);

  return (
    <div className="container main">
      {!errorResponse && <DateFilter startDate={startDate} endDate={endDate} setDateRange={setDateRange} />}
      {!errorResponse && <TransactionDetails detailTransaction={detailTransaction} setDetailTransaction={setDetailTransaction} />}
      {errorResponse ? <Message value={errorResponse} error={true} /> : renderTransactionsTable()}
    </div>
  );
}

export default TransactionsTable;