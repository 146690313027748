import './App.css';
import Menu from './components/Menu';
import Routes from './pages/Routes';
import { useAuth } from 'oidc-react';
import { appConfig } from './config/appConfig';
import Footer from './components/Footer';

const App = (envConfig: EnvConfig) => {
  const config = appConfig(envConfig, useAuth());
  if (config) {
    return (
      <div className="App">
        <Menu {...config} />
        <Routes {...config} />
        <Footer />
      </div>
    );
  }

  return (
    <div>Authenticating...</div>
  )
}

export default App;
